import lazyLoading from './lazyLoading'

export default [
  {
    name: 'dashboard.login',
    path: '/dashboard/login',
    default: true,
    component: () => import('@/components/Login.vue'),
    meta: {
      public: true
    }
  },
  {
    name: 'dashboard.forgot_password',
    path: '/forgot-password',
    component: () => import('@/components/ForgotPassword.vue'),
    meta: {
      public: true
    }
  },
  {
    name: 'dashboard.update_password',
    path: '/update-password/:token/email/:email',
    component: () => import('@/components/UpdatePassword.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/dashboard',
    component: () => import('@/components/layout/dashboard/Index'),
    children: [
      {
        name: 'dashboard.home',
        path: '/dashboard/home',
        // Relative to /src/views
        component: lazyLoading('dashboard/Home'),
        meta: {
          public: false,
          breadcrumb: 'Home'
        }
      },
      {
        name: 'dashboard.user',
        path: '/dashboard/user',
        component: lazyLoading('dashboard/user/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'User',
            parent: 'dashboard.home'
          },
          permission: 'user.view'
        },
        children: [
          {
            name: 'dashboard.user-update-password',
            path: '/dashboard/user/:id/password',
            component: lazyLoading('dashboard/user/UpdatePassword'),
            meta: {
              public: false,
              breadcrumb: {
                label: 'Change Password',
                parent: 'dashboard.user'
              },
              permission: 'user.update.password'
            }
          }
        ]
      },
      {
        name: 'dashboard.user-create',
        path: '/dashboard/user/create',
        component: lazyLoading('dashboard/user/Create'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Create',
            parent: 'dashboard.user'
          },
          permission: 'user.create'
        }
      },
      {
        name: 'dashboard.user-update',
        path: '/dashboard/user/:id/update',
        component: lazyLoading('dashboard/user/Update'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Update',
            parent: 'dashboard.user'
          },
          permission: 'user.update'
        }
      },
      {
        name: 'dashboard.user-view',
        path: '/dashboard/user/:id',
        component: lazyLoading('dashboard/user/View'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'View',
            parent: 'dashboard.user'
          }
        }
      },
      {
        name: 'dashboard.permission',
        path: '/dashboard/permission',
        component: lazyLoading('dashboard/permission/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Permission',
            parent: 'dashboard.home'
          },
          permission: 'permission.view'
        }
      },
      {
        name: 'dashboard.role',
        path: '/dashboard/role',
        component: lazyLoading('dashboard/role/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Role',
            parent: 'dashboard.home'
          },
          permission: 'role.view'
        }
      },
      {
        name: 'dashboard.role-create',
        path: '/dashboard/role/create',
        component: lazyLoading('dashboard/role/Create'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Create',
            parent: 'dashboard.role'
          },
          permission: 'role.create'
        }
      },
      {
        name: 'dashboard.role-update',
        path: '/dashboard/role/:id/update',
        component: lazyLoading('dashboard/role/Update'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Update',
            parent: 'dashboard.role'
          },
          permission: 'role.update'
        }
      },
      {
        name: 'dashboard.quiz',
        path: '/dashboard/quiz',
        component: lazyLoading('dashboard/quiz/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Quiz',
            parent: 'dashboard.home'
          },
          permission: 'quiz.view'
        }
      },
      {
        name: 'dashboard.quiz-create',
        path: '/dashboard/quiz/create',
        component: lazyLoading('dashboard/quiz/Create'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Create',
            parent: 'dashboard.quiz'
          },
          permission: 'quiz.create'
        }
      },
      {
        name: 'dashboard.quiz-update',
        path: '/dashboard/quiz/:id/update',
        component: lazyLoading('dashboard/quiz/Update'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Update',
            parent: 'dashboard.quiz'
          },
          permission: 'quiz.update'
        }
      },
      {
        name: 'dashboard.question',
        path: '/dashboard/question/:quiz_id?',
        component: lazyLoading('dashboard/question/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Question',
            parent: 'dashboard.quiz'
          },
          permission: 'question.view'
        }
      },
      {
        name: 'dashboard.question-create',
        path: '/dashboard/question/:quiz_id?/create',
        component: lazyLoading('dashboard/question/Create'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Create',
            parent: 'dashboard.question'
          },
          permission: 'question.create'
        }
      },
      {
        name: 'dashboard.question-update',
        path: '/dashboard/question/:id/update',
        component: lazyLoading('dashboard/question/Update'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Update',
            parent: 'dashboard.question'
          },
          permission: 'question.update'
        }
      },
      {
        name: 'dashboard.answer',
        path: '/dashboard/answer',
        component: lazyLoading('dashboard/answer/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Answer',
            parent: 'dashboard.home'
          },
          permission: 'answer.view'
        }
      },
      {
        name: 'dashboard.answer-create',
        path: '/dashboard/answer/create',
        component: lazyLoading('dashboard/answer/Create'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Create',
            parent: 'dashboard.answer'
          },
          permission: 'answer.create'
        }
      },
      {
        name: 'dashboard.answer-update',
        path: '/dashboard/answer/:id/update',
        component: lazyLoading('dashboard/answer/Update'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Update',
            parent: 'dashboard.answer'
          },
          permission: 'answer.update'
        }
      },
      {
        name: 'dashboard.result',
        path: '/dashboard/result',
        component: lazyLoading('dashboard/result/Index'),
        meta: {
          public: false,
          breadcrumb: {
            label: 'Result',
            parent: 'dashboard.home'
          },
          permission: 'result.view'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/components/layout/Index'),
    children: [
      {
        name: 'home',
        path: '/:id',
        component: lazyLoading('Home'),
        meta: {
          public: true
        }
      },
      {
        name: 'quiz',
        path: '/quiz/:id/question/:question_id',
        component: lazyLoading('quiz/Main'),
        meta: {
          public: true
        }
      },
      {
        name: 'reward',
        path: '/reward/:quiz_id',
        component: lazyLoading('quiz/Reward'),
        meta: {
          public: true
        }
      }
    ]
  },
  {
    name: 'error',
    path: '/error',
    component: () => import('@/components/layout/ErrorPage'),
    children: [
      {
        name: 'denied',
        path: '/access-denied',
        component: lazyLoading('page/403'),
        meta: {
          public: true
        }
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/components/layout/ErrorPage'),
    children: [
      {
        path: '/',
        name: '404',
        component: lazyLoading('page/404'),
        meta: {
          public: true
        }
      }
    ]
  }
]
