import { forEachObjIndexed } from 'ramda'

export default {
  methods: {
    displayError (error) {
      const { status, data } = error.response
      switch (status) {
        case 401:
          this.$store.commit('setNotification', { color: 'error', message: 'Invalid credentials' })
          return
        case 422:
          forEachObjIndexed(
            (value) => this.$store.commit('setNotification', { color: 'error', message: value[0] }),
            data.errors
          )
          return
      }

      throw error
      // display validation errors
      // if (error.response.data.errors) {
      //   forEachObjIndexed(
      //     (value, key) => this.$store.commit('setNotification', { color: 'error', message: value[0] }),
      //     error.response.data.errors
      //   )
      // }
    }
  }
}
