import moment from 'moment'

export const AUTH_TOKEN = 'bearer_auth_token'
const AUTH_TOKEN_EXPIRE = 'token_expires_in'

export default function authHeader () {
  return { Authorization: 'Bearer ' + localStorage.getItem(AUTH_TOKEN) }
}

export function login (user) {
  localStorage.setItem('user', JSON.stringify(user.user))
  localStorage.setItem(AUTH_TOKEN_EXPIRE, moment().add(user.expires_in, 'seconds').format())
  localStorage.setItem(AUTH_TOKEN, user.token)
}

export function logout () {
  localStorage.clear()
  localStorage.removeItem('user')
  localStorage.removeItem(AUTH_TOKEN_EXPIRE)
  localStorage.removeItem(AUTH_TOKEN)
}

export function getTokenTtl () {
  const expireDateTime = localStorage.getItem(AUTH_TOKEN_EXPIRE)
  return moment(expireDateTime).diff(moment(), 'seconds')
}
