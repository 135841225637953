import { defineAbility } from '@casl/ability'

const ability = defineAbility(can => {
  // if exist user then parse it
  if (localStorage.getItem('user')) {
    const user = JSON.parse(localStorage.getItem('user'))
    const permissions = parseUserPermissions(user)
    can(permissions, 'all')
  }
})

/**
 * Define permission to system
 * @param permissions array ex ['user.view', 'role.view']
 */
export function setPermissions (permissions) {
  ability.update([{ subject: 'all', action: permissions }])
}

export function parseUserPermissions (user) {
  const permissions = []
  user.roles.forEach(role =>
    role.permissions.forEach(
      permission => permissions.push(permission.name)
    )
  )
  return permissions
}

export default ability
