import Vue from 'vue'
import VueMoment from 'vue-moment'
import Vue2Crumbs from 'vue-2-crumbs'
import { abilitiesPlugin } from '@casl/vue'
import ability from './ability'
require('moment/locale/en-ca')

// set default time UTC
// moment.tz.setDefault('UTC')

Vue.use(VueMoment)
Vue.use(Vue2Crumbs)
Vue.use(abilitiesPlugin, ability)
