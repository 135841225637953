import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/i18n'
import '@/plugins/index'
import '@/plugins/veevalidate'
import '@/plugins/editor'
import errorMixin from '@/mixins/errors'
import axios from 'axios'

Vue.mixin(errorMixin)

// todo made it via util lib
Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_HTTP,
  headers: {
    'Content-type': 'application/json'
  }
})
Vue.prototype.$getFullPath = (relativePath) => {
  return process.env.VUE_APP_SERVER + 'storage/' + relativePath
}
Vue.prototype.$strippedContent = (string) => {
  return string.replace(/<\/?[^>]+>/ig, ' ')
}
/**
 * Convert blob file into Image
 * @param image accept blob or file object
 * */
Vue.prototype.$displayImage = (image) => {
  if (!image) {
    return
  }

  return URL.createObjectURL(image)
}
Vue.prototype.$getColor = (status) => {
  return status ? 'green' : 'red'
}
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
