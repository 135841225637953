import Vue from 'vue'
import Router from 'vue-router'
// Routes
import paths from './paths'
import ability from '@/plugins/ability'
import { getTokenTtl, AUTH_TOKEN } from '@/utils'

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: paths
})

// if uncatch error in promise https://github.com/vuejs/vue-router/issues/2932
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const user = JSON.parse(localStorage.getItem('user'))
  const token = localStorage.getItem(AUTH_TOKEN)
  const ttl = getTokenTtl()

  if (to.meta.public === false && (!user || !token || ttl < 0)) {
    next({ name: 'dashboard.login' })
  }

  // check that user has permission to see page
  if (to.meta.permission && !ability.can(to.meta.permission)) {
    next({ name: 'denied' })
  }

  next()
})

export default router
